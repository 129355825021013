var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2 new-user-on-boarding"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-flex align-items-start auth-bg px-2 p-lg-5-update",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-link',{staticClass:"brand-logo-update"},[_c('DefaultLogo')],1),_c('ul',{staticClass:"lang-sign-up"},[_c('locale')],1)],1),_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t("user.InvitedLogin", { role: _vm.inviteData.role }))+" "),_c('span',{staticClass:"inviteDatacss"},[_vm._v(" "+_vm._s(_vm.inviteData.account_name)+" ")])]),_c('b-card-text',{staticClass:"bt-40"},[_vm._v(" "+_vm._s(_vm.$t("user.AcceptInvite"))+" ")]),_c('validation-observer',{ref:"signupValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.EnterFn')}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"First Name","rules":{
                        required: true,
                        min: 2,
                        max: 50
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                          'is-invalid': errors.length > 0,
                          'is-valid':
                            errors.length === 0 && _vm.signup_data.first_name
                        }},[_c('b-form-input',{class:{
                            'is-valid':
                              errors.length === 0 &&
                              _vm.signup_data.first_name.trim(),
                            'is-invalid': errors.length > 0
                          },attrs:{"name":"first name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('user.EnterFnPlace')},model:{value:(_vm.signup_data.first_name),callback:function ($$v) {_vm.$set(_vm.signup_data, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signup_data.first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.EnterLn')}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"Last Name","rules":{
                        required: true,
                        min: 2,
                        max: 50
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                          'is-invalid': errors.length > 0,
                          'is-valid':
                            errors.length === 0 &&
                            _vm.signup_data.last_name.trim()
                        }},[_c('b-input-group-prepend',{class:{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 && _vm.signup_data.last_name
                          },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{class:errors.length == 0 && _vm.signup_data.last_name
                              ? 'is-valid'
                              : null,attrs:{"name":"first name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('user.EnterLnPlace')},model:{value:(_vm.signup_data.last_name),callback:function ($$v) {_vm.$set(_vm.signup_data, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signup_data.last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.Email')}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"type":"text","disabled":"","placeholder":_vm.$t('user.EnterEmail')},model:{value:(_vm.signup_data.email),callback:function ($$v) {_vm.$set(_vm.signup_data, "email", $$v)},expression:"signup_data.email"}})],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.Username')}},[_c('validation-provider',{attrs:{"name":"User name","vid":"Username","rules":{
                        required: true,
                        min: 3,
                        max: 20
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                          'is-invalid': errors.length > 0,
                          'is-valid': errors.length === 0 && _vm.userName
                        }},[_c('b-input-group-prepend',{class:{
                            'is-invalid': errors.length > 0,
                            'is-valid': errors.length === 0 && _vm.userName
                          },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{class:{
                            'is-invalid': errors.length > 0,
                            'is-valid': errors.length === 0 && _vm.userName
                          },style:(_vm.userName ? 'text-transform: lowercase' : ''),attrs:{"id":"username","state":errors.length > 0 ? false : null,"type":"text","debounce":"500","name":"user name","placeholder":_vm.$t('login.RequiredUserName')},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : "")+_vm._s(_vm.phoneError))])]}}],null,true)}),(!_vm.isUserExists)?_c('span',{staticClass:"usernametop"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("errors.UserNameAlreadyExists")))])]):_vm._e(),(_vm.user_name_show)?_c('span',{staticClass:"spin-position"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e()],1)],1),_c('b-col',{staticClass:"input-ui-design",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.Mobile')}},[_c('VuePhoneNumberInput',{attrs:{"default-country-code":_vm.countryCode},on:{"update":_vm.countryChanged},model:{value:(_vm.mobileNo),callback:function ($$v) {_vm.mobileNo=$$v},expression:"mobileNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.mobileHasError ? _vm.mobileHasError : ""))])],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.Password')}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":{
                        required: true,
                        regex: /^.{5,25}$/
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                          'is-invalid': errors.length > 0,
                          'is-valid':
                            errors.length === 0 && _vm.signup_data.new_password
                        }},[_c('b-input-group-prepend',{class:{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 && _vm.signup_data.new_password
                          },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{staticClass:"form-control-merge",class:{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 && _vm.signup_data.new_password
                          },attrs:{"id":"password","type":_vm.newPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":_vm.$t('profile.PassPlaceholder')},model:{value:(_vm.signup_data.new_password),callback:function ($$v) {_vm.$set(_vm.signup_data, "new_password", $$v)},expression:"signup_data.new_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.newPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('new_pass')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.RetypePass')}},[_c('validation-provider',{attrs:{"name":"Retype Password","vid":"RetypePassword","rules":{
                        required: true,
                        regex: /^.{5,25}$/
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('span',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(!errors || !errors.length ? _vm.signup_data.confirm_password != _vm.signup_data.new_password ? (errors = [_vm.$t("profile.PasswordMismatch")]) : "" : ""))]),_c('b-input-group',{staticClass:"input-group-merge",class:{
                          'is-invalid': errors.length > 0,
                          'is-valid':
                            errors.length === 0 &&
                            _vm.signup_data.confirm_password
                        }},[_c('b-input-group-prepend',{class:{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 &&
                              _vm.signup_data.confirm_password
                          },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{staticClass:"form-control-merge",class:{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 &&
                              _vm.signup_data.confirm_password
                          },attrs:{"id":"RetypePassword","type":_vm.confirmPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"RetypePassword","placeholder":_vm.$t('profile.PassPlaceholder')},model:{value:(_vm.signup_data.confirm_password),callback:function ($$v) {_vm.$set(_vm.signup_data, "confirm_password", $$v)},expression:"signup_data.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('confirm_pass')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mt-16 w-50",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("user.RegisterWithInvite", { account: _vm.inviteData.account_name }))+" ")])],1),_c('div',{staticClass:"hasErrorCss"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.hasError))])])],1)]}}])})],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("user.BeenHere"))+" ")]),_c('b-link',{on:{"click":_vm.redirectToLogin}},[_c('small',[_vm._v(_vm._s(_vm.$t("user.Login")))])])],1)],1)],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 pd-0",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"},[_c('div',{staticClass:"login-bg"},[_c('LoginImage',{staticClass:"object-login-img"})],1)])])],1),_c('Loader',{attrs:{"show":_vm.show}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }