<template>
  <div class="auth-wrapper auth-v2 new-user-on-boarding">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-start auth-bg px-2 p-lg-5-update">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div>
            <!-- Brand logo-->
            <div class="d-flex justify-content-between">
              <b-link class="brand-logo-update">
                <DefaultLogo />
              </b-link>
              <ul class="lang-sign-up">
                <locale />
              </ul>
            </div>
            <!-- /Brand logo-->
            <b-card-title>
              {{
                $t("user.InvitedLogin", {
                  role: inviteData.role
                })
              }}
              <span class="inviteDatacss">
                {{ inviteData.account_name }}
              </span>
            </b-card-title>

            <b-card-text class="bt-40">
              {{ $t("user.AcceptInvite") }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="signupValidation" #default="{ invalid }">
              <b-form class="auth-login-form mt-4" @submit.prevent="register">
                <b-row>
                  <b-col lg="6">
                    <b-form-group :label="$t('user.EnterFn')">
                      <!-- <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        vid="First Name"
                        :rules="{
                          required: true,
                          min: 2,
                          max: 50,
                          regex: /^[a-zA-Z0-9\s'-]+$/u
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 && signup_data.first_name
                          }"
                        >
                          <b-input-group-prepend
                            is-text
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid':
                                errors.length === 0 && signup_data.first_name
                            }"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            name="first name"
                            :class="
                              errors.length == 0 && signup_data.first_name
                                ? 'is-valid'
                                : null
                            "
                            :state="errors.length > 0 ? false : null"
                            v-model="signup_data.first_name"
                            :placeholder="$t('user.EnterFnPlace')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider> -->
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        vid="First Name"
                        :rules="{
                          required: true,
                          min: 2,
                          max: 50
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 && signup_data.first_name
                          }"
                        >
                          <b-form-input
                            name="first name"
                            :class="{
                              'is-valid':
                                errors.length === 0 &&
                                signup_data.first_name.trim(),
                              'is-invalid': errors.length > 0
                            }"
                            :state="errors.length > 0 ? false : null"
                            v-model.trim="signup_data.first_name"
                            :placeholder="$t('user.EnterFnPlace')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group :label="$t('user.EnterLn')">
                      <validation-provider
                        #default="{ errors }"
                        name="Last Name"
                        vid="Last Name"
                        :rules="{
                          required: true,
                          min: 2,
                          max: 50
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 &&
                              signup_data.last_name.trim()
                          }"
                        >
                          <b-input-group-prepend
                            is-text
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid':
                                errors.length === 0 && signup_data.last_name
                            }"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            name="first name"
                            :class="
                              errors.length == 0 && signup_data.last_name
                                ? 'is-valid'
                                : null
                            "
                            :state="errors.length > 0 ? false : null"
                            v-model.trim="signup_data.last_name"
                            :placeholder="$t('user.EnterLnPlace')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group :label="$t('user.Email')">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          type="text"
                          disabled
                          v-model="signup_data.email"
                          :placeholder="$t('user.EnterEmail')"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group :label="$t('user.Username')">
                      <validation-provider
                        #default="{ errors }"
                        name="User name"
                        vid="Username"
                        :rules="{
                          required: true,
                          min: 3,
                          max: 20
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="{
                            'is-invalid': errors.length > 0,
                            'is-valid': errors.length === 0 && userName
                          }"
                        >
                          <b-input-group-prepend
                            is-text
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid': errors.length === 0 && userName
                            }"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="username"
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid': errors.length === 0 && userName
                            }"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            debounce="500"
                            :style="userName ? 'text-transform: lowercase' : ''"
                            v-model="userName"
                            name="user name"
                            :placeholder="$t('login.RequiredUserName')"
                          />
                        </b-input-group>
                        <small class="text-danger"
                          >{{ errors[0] ? handleError(errors[0]) : ""
                          }}{{ phoneError }}</small
                        >
                      </validation-provider>
                      <span class="usernametop" v-if="!isUserExists">
                        <small class="text-danger">{{
                          $t("errors.UserNameAlreadyExists")
                        }}</small>
                      </span>
                      <span class="spin-position" v-if="user_name_show">
                        <b-spinner
                          variant="primary"
                          label="Spinning"
                        ></b-spinner>
                      </span>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Time Zone"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('profile.TimeZone') + '*'"
                        class="is-valid"
                      >
                        <v-select
                          id="vue-select"
                          v-model="signup_data.timezone"
                          :options="TZoption"
                          :placeholder="$t('profile.TimeZone')"
                          :reduce="(option) => option.value"
                          label="label"
                          class="time-h"
                        />
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col> -->

                  <b-col lg="6" class="input-ui-design">
                    <b-form-group :label="$t('user.Mobile')">
                      <VuePhoneNumberInput
                        @update="countryChanged"
                        :default-country-code="countryCode"
                        v-model="mobileNo"
                      />

                      <small class="text-danger">
                        {{ mobileHasError ? mobileHasError : "" }}</small
                      >
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group :label="$t('profile.Password')">
                      <validation-provider
                        #default="{ errors }"
                        name="password"
                        vid="password"
                        :rules="{
                          required: true,
                          regex: /^.{5,25}$/
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 && signup_data.new_password
                          }"
                        >
                          <b-input-group-prepend
                            is-text
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid':
                                errors.length === 0 && signup_data.new_password
                            }"
                          >
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid':
                                errors.length === 0 && signup_data.new_password
                            }"
                            id="password"
                            :type="newPasswordFieldType"
                            v-model="signup_data.new_password"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="password"
                            :placeholder="$t('profile.PassPlaceholder')"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="newPasswordToggleIcon"
                              @click="togglePasswordVisibility('new_pass')"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group :label="$t('profile.RetypePass')">
                      <validation-provider
                        #default="{ errors }"
                        name="Retype Password"
                        vid="RetypePassword"
                        :rules="{
                          required: true,
                          regex: /^.{5,25}$/
                        }"
                      >
                        <span style="display: none">
                          {{
                            !errors || !errors.length
                              ? signup_data.confirm_password !=
                                signup_data.new_password
                                ? (errors = [$t("profile.PasswordMismatch")])
                                : ""
                              : ""
                          }}</span
                        >
                        <b-input-group
                          class="input-group-merge"
                          :class="{
                            'is-invalid': errors.length > 0,
                            'is-valid':
                              errors.length === 0 &&
                              signup_data.confirm_password
                          }"
                        >
                          <b-input-group-prepend
                            is-text
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid':
                                errors.length === 0 &&
                                signup_data.confirm_password
                            }"
                          >
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="RetypePassword"
                            :type="confirmPasswordFieldType"
                            v-model="signup_data.confirm_password"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="RetypePassword"
                            :placeholder="$t('profile.PassPlaceholder')"
                            :class="{
                              'is-invalid': errors.length > 0,
                              'is-valid':
                                errors.length === 0 &&
                                signup_data.confirm_password
                            }"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="confirmPasswordToggleIcon"
                              @click="togglePasswordVisibility('confirm_pass')"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="d-flex justify-content-center">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    class="mt-16 w-50"
                    :disabled="invalid"
                  >
                    {{
                      $t("user.RegisterWithInvite", {
                        account: inviteData.account_name
                      })
                    }}
                  </b-button>
                </div>
                <div class="hasErrorCss">
                  <small class="text-danger">{{ hasError }}</small>
                </div>
              </b-form>
            </validation-observer>
          </div>

          <!-- <b-form-checkbox
            v-model="rememberMe"
            class="form-control-merge cursor-pointer"
          >
            {{ $t("RememberMe") }}
          </b-form-checkbox> -->

          <b-card-text class="text-center mt-2">
            <span>{{ $t("user.BeenHere") }} </span>

            <b-link @click="redirectToLogin">
              <small>{{ $t("user.Login") }}</small>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->

      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 pd-0">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"
        >
          <div class="login-bg">
            <LoginImage class="object-login-img" />
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
    <Loader :show="show" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import * as countries from "countries-phone-masks";
import Loader from "@/layouts/components/Loader.vue";
import Menus from "@/navigation/vertical/index";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend,
  BAvatar,
  BFormSelect,
  FormSelectPlugin,
  BSpinner
} from "bootstrap-vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccountService from "@/libs/api/account-service";
import AuthService from "@/libs/api/auth-service";

import { phone } from "phone";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import constants from "@/utils/constants";
import { v4 as uuidv4 } from "uuid";
import LoginImage from "@/assets/images/pages/login-v2.svg";
import Locale from "@/layouts/components/Locale.vue";
import acl from "@/utils/acl";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    Loader,
    BButton,
    DefaultLogo,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BAvatar,
    BFormSelect,

    VuePhoneNumberInput,
    LoginImage,
    BSpinner,
    Locale
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      mobileNo: "",
      hasError: "",
      countryCode: "US",
      mobileHasError: "",
      isMobileValid: false,
      rememberMe: "",
      signup_data: {
        token: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        username: "",
        new_password: "",
        confirm_password: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      isUserExists: true,
      phoneError: "",
      userName: "",
      TZoption: constants.TZStrings,
      countryCodeList: countries || [],
      phone: "",
      isValidPhoneNumber: true,
      confirmPassword: "",
      show: false,
      user_name_show: false,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      inviteData: {},
      selectedCountry: "+91",
      email
    };
  },
  computed: {
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === "password"
        ? "EyeOffIcon"
        : "EyeIcon";
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeOffIcon"
        : "EyeIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  mounted() {
    this.inviteData = JSON.parse(localStorage.getItem("inviteData"));
    this.signup_data.email = this.inviteData.email;
    this.countryCode = this.inviteData.country || this.countryCode;
    this.signup_data.token = this.inviteData.token;
    this.countryCodeList = this.countryCodeList.map((c) => {
      return { value: c.code, label: `${c.code} (${c.iso})` };
    });
  },
  watch: {
    userName() {
      this.checkUserNameAvailability();
    }
  },
  methods: {
    async register() {
      let hasErr = "";
      const me = this;
      if (!me.isUserExists) {
        hasErr = $t("errors.UserNameAlreadyExists");
      } else if (!this.signup_data.mobile) {
        hasErr = me.$t("errors.invalidMobile");
      } else if (!this.isValidEmail(me.signup_data.email)) {
        hasErr = me.$t("errors.invalidEmailErrMsg");
      } else if (!this.isValidPassword(me.signup_data.new_password)) {
        hasErr = me.$t("errors.Password_Valid");
      } else if (!this.isValidPassword(me.signup_data.confirm_password)) {
        hasErr = me.$t("errors.Password_Valid");
      } else if (
        me.signup_data.new_password !== me.signup_data.confirm_password
      ) {
        hasErr = me.$t("profile.PasswordMismatch");
      } else if (
        me.signup_data.new_password.toLowerCase() === me.userName.toLowerCase()
      ) {
        hasErr = me.$t("profile.UserNamePasswordShouldNotSame");
      }

      if (this.signup_data.mobile) {
        const mobileDetailsCheck = phone(this.signup_data.mobile);
        if (!mobileDetailsCheck || !mobileDetailsCheck.countryIso2) {
          me.mobileHasError = me.$t("errors.invalidMobile");
          return;
        } else {
          me.mobileHasError = "";
        }
        const mobileDetails = phone(this.signup_data.mobile, {
          country: mobileDetailsCheck.countryIso2
        });
        if (!mobileDetails || !mobileDetails.isValid) {
          me.mobileHasError = me.$t("errors.invalidMobile");
          return;
        } else {
          me.mobileHasError = "";
        }
      }
      if (hasErr && hasErr !== "") {
        me.hasError = hasErr;
        return;
      }
      const resp = {
        token: me.signup_data.token,
        first_name: me.signup_data.first_name,
        last_name: me.signup_data.last_name,
        email: me.signup_data.email,
        mobile: this.signup_data.mobile,
        username: me.userName && me.userName.toLowerCase(),
        password: me.signup_data.new_password,
        timezone: me.signup_data.timezone
      };
      me.show = true;
      let response = await new AccountService().registerUserWithInvite(resp);
      if (response && response.data) {
        me.$toast({
          component: ToastificationContent,
          props: {
            title: me.$t("user.UserAccCreatedTitle"),
            message: me.$t("user.UserAccCreatedMsg"),
            icon: "EditIcon",
            variant: "success"
          }
        });
        const obj = {};
        if (localStorage.getItem("sessionId")) {
          obj.session_id = localStorage.getItem("sessionId");
        }

        try {
          const logout = await new AuthService().logout({
            ...obj
          });
        } catch (err) {
          console.log(err);
        }
        localStorage.removeItem("USER_LOGIN_ID");
        localStorage.removeItem("USER_PERMISSIONS");
        localStorage.removeItem("OLD_USER_ACCOUNT");
        localStorage.removeItem("ACCOUNT_ID");

        localStorage.removeItem("inviteData");
        localStorage.removeItem("USER_ACCOUNT_WHITE_LABELING");

        if (localStorage.getItem("notificationToken")) {
          obj.notification_token = localStorage.getItem("notificationToken");
        }
        const sessionId = uuidv4();
        localStorage.setItem("sessionId", sessionId);
        obj.session_id = sessionId;
        // if (this.rememberMe) {
        //   obj.remember = true;
        // }
        let loginResp = await new AuthService().login({
          username: me.userName.toLowerCase(),
          password: me.signup_data.new_password,
          ...obj
        });

        if (loginResp && loginResp.data && loginResp.data.user_token) {
          me.show = false;
          me.hasErr = "";
          localStorage.setItem(
            useJwt.jwtConfig.storageTokenKeyName,
            loginResp.data.user_token
          );
          // if (this.rememberMe) {
          //   if (loginResp.data.bearer_token) {
          //     localStorage.setItem("BEARER_TOKEN", loginResp.data.bearer_token);
          //   }
          // }
          if (loginResp.data.accounts && loginResp.data.accounts.length) {
            if (loginResp.data.user && loginResp.data.user.id) {
              localStorage.setItem("USER_LOGIN_ID", loginResp.data.user.id);
            }
            const route = await me.selectedUserAccount(
              loginResp.data.accounts[0]
            );
            if (route) {
              try {
                if (route) {
                  me.$router.push({ name: route }).catch((err) => {
                    console.log(err);
                  });
                  setTimeout(() => {
                    me.$router.go();
                  }, 200);
                } else {
                  me.$router.push({ name: "user-invitations" });
                  setTimeout(() => {
                    me.$router.go();
                  }, 200);
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
        } else if (loginResp && loginResp.error && loginResp.error.message) {
          me.show = false;
          me.hasErr = loginResp.error.message;
          return;
        }
      } else if (response && response.error && response.error.message) {
        me.show = false;
        me.hasError = response.error.message;
        return;
      }
    },
    async selectedUserAccount(account) {
      try {
        const requestData = {};

        const newSession = uuidv4();

        localStorage.setItem("SESSION_LOGIN", newSession);

        requestData.new_session = newSession;
        requestData.account_id = account.account_id;

        localStorage.setItem("USER_ACCOUNT_ID", account.account_id);
        localStorage.setItem(
          "USER_SUB_ACCOUNTS_RESTRICTED",
          account.restrict_sub_accounts
        );
        localStorage.setItem("ACCOUNT_ID", account.account_id);
        localStorage.setItem("USER_ACCOUNT_TYPE", account.type);

        store.commit("account/setAccountType", account.type);
        localStorage.setItem("USER_ACCOUNT_ROLE_NAME", account.role);
        localStorage.setItem("USER_ACCOUNT_NAME", account.account_name);
        localStorage.setItem("USER_ACCOUNT_ROLE_ID", account.main_role);

        // call select account api.
        let response = await new AccountService().selectAccount({
          account_id: account.account_id,
          ...requestData
        });
        if (response && response.data) {
          if (localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true") {
            if (
              ["DEALER", "CONSUMER"].includes(
                localStorage.getItem("USER_ACCOUNT_TYPE")
              )
            ) {
              this.getFilteredLists(["account", "account_devices"], response); //pass in array which we want to exclude
            }
          } else if (localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER") {
            this.getFilteredLists(["devices"], response);
          }

          acl.parseAndStoreAbilities(response.data, false, true);

          if (
            this.checkAbility({
              action: constants.LABELS.MENU,
              subject: "units"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            const query = localStorage.getItem("USER_ACCOUNT_ID")
              ? { account_id: localStorage.getItem("USER_ACCOUNT_ID") }
              : null;
            this.$router
              .push({
                name: "unit-map",
                query
              })
              .catch((err) => {});
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "DEALER"
          ) {
            this.$router.push({
              name: "home"
            });
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account_devices"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            this.$router.push({
              name: "dashboard"
            });
          } else {
            try {
              Menus.forEach((element) => {
                if (
                  this.checkAbility({
                    action: element.action,
                    subject: element.resource
                  }) &&
                  element.route
                ) {
                  throw element;
                } else {
                  if (element && element.children && element.children.length) {
                    element.children.forEach((child) => {
                      if (
                        this.checkAbility({
                          action: child.action,
                          subject: child.resource
                        })
                      ) {
                        throw child;
                      }
                    });
                  }
                }
              });
            } catch (e) {
              if (e.route) {
                this.$router.push({ name: e.route });
              } else {
                // localStorage.setItem("TEMP_URL", "user-invitations");
                this.$router.push({ name: "user-invitations" });

                // if (oldPermission && oldPermission !== newPermission) {
                //   this.$router.go();
                // } else {
                //   this.$router.push({ name: "user-invitations" });
                // }
              }
            }
          }

          // this.$router.go();
          // this.showAcceptInvitationScreen();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    getFilteredLists(filter, response) {
      response.data = Object.keys(response.data)
        .filter((key) => ![].includes(key))
        .reduce((obj, key) => {
          if (filter.includes(key)) {
            if (
              response.data[key] &&
              response.data[key].length &&
              response.data[key].includes("dash")
            ) {
              obj[key] = [constants.LABELS.DASHBOARD];
            }
          } else {
            obj[key] = response.data[key];
          }

          return obj;
        }, {});
    },
    async redirectToLogin() {
      await new AuthService().logout({
        session_id: localStorage.getItem("sessionId")
      });
      // Redirect to login page
      this.$router.push("/");
    },
    countryChanged(e) {
      let me = this;
      let hasErr = "";

      if (e.formattedNumber) {
        const mobileDetailsCheck = phone(e.formattedNumber);
        if (!mobileDetailsCheck || !mobileDetailsCheck.countryIso2) {
          me.mobileHasError = me.$t("errors.invalidMobile");
          this.isMobileValid = false;

          return;
        }
        const mobileDetails = phone(e.formattedNumber, {
          country: mobileDetailsCheck.countryIso2
        });
        this.selectedCountry =
          (mobileDetails && mobileDetails.countryIso2) || null;
        // this.countryCallingCode = mobileDetails && mobileDetails.countryCode;
        if (
          !mobileDetails ||
          !mobileDetails.isValid ||
          !mobileDetails.countryCode
        ) {
          this.isMobileValid = false;
          hasErr = me.$t("errors.invalidMobile");
        }
        if (mobileDetails.isValid) {
          this.isMobileValid = true;
        } else {
          this.isMobileValid = false;
        }
      } else {
        this.isMobileValid = false;
        return;
      }
      this.signup_data.mobile = e.formattedNumber;
      if ((hasErr && hasErr !== "") || !this.signup_data.mobile) {
        if (!this.signup_data.mobile) {
          hasErr = this.$t("profile.MobileRequired");
        }
        me.mobileHasError = hasErr;
        this.isMobileValid = false;
        return;
      } else {
        me.mobileHasError = "";
        this.isMobileValid = true;
        return;
      }
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    async checkUserNameAvailability() {
      try {
        this.user_name_show = true;
        let response = await new AccountService().checkUserNameAvailability({
          username: this.userName.toLowerCase()
        });

        if (response && response.data) {
          this.user_name_show = false;
          this.isUserExists = true;
          this.hasError = "";
        } else if (response && response.error && response.error.message) {
          this.isUserExists = false;
          this.user_name_show = false;
        }
      } catch (err) {
        this.user_name_show = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
.county-select .vs__search {
  min-width: 60px;
}
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.fill-primary {
  fill: var(--primary);
}
.inviteDatacss {
  font-size: 23px;
  font-weight: bold;
}
.usernametop {
  margin-top: 10px;
}
.hasErrorCss {
  margin-top: 10px;
}
.new-user-on-boarding {
  .auth-login-form {
    .form-group {
      margin-bottom: 0rem;
      position: relative;
      .text-danger {
        position: relative;
        line-height: 14px;
        min-height: 26px;
        display: inline-block;
        width: 100%;
      }
      .spin-position {
        position: absolute;
        top: 5px;
        right: 7px;
      }
    }
  }
}
.vs__selected-options {
  width: calc(100% - 18%);
}
</style>
